<template>
  <div>
    <v-toolbar
      color="white"
      tabs
    >
      <v-app-bar-nav-icon></v-app-bar-nav-icon>

      <v-toolbar-title>Keystone v2</v-toolbar-title>

      <v-spacer></v-spacer>

      <EntityAutocomplete
        class="mt-8"
        :disable="disabledAutoSearch"
      >
      </EntityAutocomplete>

      <template v-slot:extension>
        <v-tabs
          v-model="tab"
          background-color="gray"
          grow
        >
          <v-tabs-slider color="primary"></v-tabs-slider>

          <v-tab
            v-for="item in items"
            :key="item.title"
          >
            {{ item.title }}
            <v-chip
              close
              close-icon="close"
              v-if="getSearchedItem(item.type).text !== ''"
              @click:close="removeTabSearch(item.type)"
            >
             <span v-if="getSearchedItem(item.type).isParent">{{getSearchedItem(item.type).source}}:</span> {{getSearchedItem(item.type).text}}
            </v-chip>
          </v-tab>
        </v-tabs>
      </template>
    </v-toolbar>
    <v-tabs-items v-model="tab" style="box-shadow: 0px 0px 4px lightgray;">
      <v-tab-item
        eager
        v-for="item in items"
        :key="item.title"
        :transition="false"
        :reverse-transition="false"
      >
        <component v-bind:is="item.component"></component>
      </v-tab-item>
    </v-tabs-items>

    <v-snackbar :color="snackBarConfig.color" v-model="snackbar">
      {{snackBarConfig.message}}
      <v-btn
        dark
        text
        @click="snackbar = false"
      >
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { keystoneV2 } from '../../store/modules/keystoneV2'
import store from '../../store'
import EntityAutocomplete from '@/components/KeystoneV2/EntityAutocomplete'
import { usersMixin } from '@/mixins/usersMixin'
import { baseViewMixin } from '@/mixins/baseViewMixin'

// load the store module dynamically only when needed
if (!store.state.keystoneV2) store.registerModule('keystoneV2', keystoneV2)

export default {
  name: 'Keystone_v2',
  mixins: [usersMixin, baseViewMixin],
  components: {
    EntityAutocomplete,
    MetaHoldingView: () => import('../../src/components/KeystoneV2/MetaHoldingView.vue'),
    HoldingView: () => import('../../src/components/KeystoneV2/HoldingView.vue'),
    CompanyView: () => import('../../src/components/KeystoneV2/CompanyView'),
    SiegeView: () => import('../../src/components/KeystoneV2/SiegeView.vue'),
    MemberView: () => import('../../src/components/KeystoneV2/MemberView.vue')
  },
  data: function () {
    return {
      items: [
        { title: 'Metaholding', component: 'MetaHoldingView', type: 'metaholding' },
        { title: 'Holding', component: 'HoldingView', type: 'holding' },
        { title: 'Company', component: 'CompanyView', type: 'company' },
        { title: 'Seat', component: 'SiegeView', type: 'seat' },
        { title: 'Member', component: 'MemberView', type: 'member' }
      ],
      text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
      disabledGeneralSearch: true,
      disabledAutoSearch: false
    }
  },
  mounted () {
    this.onMounted()
    this.$store.dispatch('getApiCountries')
    this.$store.dispatch('getApiCollaborators')
  },
  methods: {
    getSearchedItem (type) {
      switch (type) {
        case 'metaholding':
          return this.$store.getters.getTabSearchMetaHoldings
        case 'holding':
          return this.$store.getters.getTabSearchHoldings
        case 'company':
          return this.$store.getters.getTabSearchCompanies
        case 'seat':
          return this.$store.getters.getTabSearchSieges
        case 'member':
          return this.$store.getters.getTabSearchMembers
        default:
          console.warn('Not implemented tabSearch entity')
          return ''
      }
    },
    removeTabSearch (type) {
      switch (type) {
        case 'metaholding':
          this.$store.commit('setTabSearchMetaHoldings', { text: '', id: null })
          break
        case 'holding':
          this.$store.commit('setTabSearchHoldings', { text: '', id: null })
          break
        case 'company':
          this.$store.commit('setTabSearchCompanies', { text: '', id: null })
          break
        case 'seat':
          this.$store.commit('setTabSearchSieges', { text: '', id: null })
          break
        case 'member':
          this.$store.commit('setTabSearchMembers', { text: '', id: null })
          break
        default:
          console.warn('Not implemented tabSearch entity')
          return ''
      }
    }
  },
  computed: {
    tab: {
      get () {
        return this.$store.getters.getCurrentTab
      },
      set (newValue) {
        this.$store.commit('setCurrentTab', newValue)
      }
    },
    snackbar: {
      get () {
        return this.$store.getters.getActiveSnackBar
      },
      set (newValue) {
        this.$store.commit('setActiveSnackBar', newValue)
      }
    },
    snackBarConfig () {
      return this.$store.getters.getSnackBarConfig
    }
  },
  watch: {
  }
}
</script>

<style scoped>
.container-keystoneV2 {
  background: white;
}
</style>

<style>
.form-block-keystoneV2 {
  padding: 1em;
  border-top: 5px solid cornflowerblue;
  height: 100%;
  width: 100%;
}

.entity-view-div {
  /* full heigth - topbar - toolbar - some security px */
  height: calc(100vh - 40px - (64px + 48px + 64px) - 10px);
  overflow-x: auto;
}

.children-table table.v-table tbody tr {
  border: 4px solid #659cfe;
}

.title-form-keystoneV2 {
  text-align: center;
  font-style: oblique;
  font-weight: 700;
  /*border-top: 5px solid cornflowerblue;*/
  margin: 4px;
  font-size: 12px;
}

/*.children-table table.v-table tbody td {*/
/*  padding: 1px !important;*/
/*}*/
</style>
