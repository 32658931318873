/**
 * @param entityType {'metaholding'|'holding'|'company'|'siege'|'member'}
 */
export function getTabForEntity (entityType) {
  let rel = {
    metaholding: 0,
    holding: 1,
    company: 2,
    siege: 3,
    member: 4
  }

  return rel[entityType]
}

/**
 * @param entity
 * @param entityType {'metaholding'|'holding'|'company'|'siege'|'member'}
 */
export function getNameForEntity (entity, entityType) {
  if (entityType === 'metaholding') {
    return entity.name
  } else if (entityType === 'holding') {
    return entity.name
  } else if (entityType === 'company') {
    return entity.name
  } else if (entityType === 'siege') {
    return entity.siege.name
  } else if (entityType === 'member') {
    return entity.member.name
  } else {
    console.warn('Not implemented entityType')
    return entity.name
  }
}

export function validateEntityType (value) {
  return ['metaholding', 'holding', 'company', 'siege', 'member'].indexOf(value) !== -1
}

/**
 * @param collaboratorsList {CollaboratorModel[]}
 * @param type {'am'|'sale'}
 * @return {{text: string, value: number}[]}
 */
export function collaboratorFilterToSelectItem (collaboratorsList, type) {
  return collaboratorsList.filter(item => item.type === type).map((item) => {
    return {
      email: item.email,
      dv_mail: item.dv_mail,
      value: item.id
    }
  })
}

/**
 * @param collaboratorsList {CollaboratorModel[]}
 * @param type {'am'|'sale'}
 * @return {number[]}
 */
export function collaboratorFilterToId (collaboratorsList, type) {
  return collaboratorsList.filter(item => item.type === type).map((item) => {
    return item.id
  })
}

/**
 * @param exclude? {InstructionDsp[]}
 * @return {TextValue[]}
 */
export function getDspSelectItems (exclude = null) {
  return [
    { text: 'Xandr', value: 'appnexus' },
    { text: 'MediaMath', value: 'mediamath' },
    { text: 'Dv360', value: 'dbm' },
    { text: 'TheTradeDesk', value: 'thetradedesk' },
    { text: 'Facebook', value: 'facebook' },
    { text: 'Beeswax', value: 'beeswax' },
    { text: 'TrueView', value: 'youtube' }
  ].filter((item) => {
    return !exclude || !Array.isArray(exclude) || !exclude.includes(item.value)
  })
}

/**
 * @param value
 * @returns {string}
 */
export function getDspTextForValue (value) {
  let selectItem = getDspSelectItems()
  return selectItem.find(item => item.value === value).text
}
